import React, { useState } from 'react'
import SearchBar, { Product } from './components/searchbar'
import RegionSelect, { Region } from './components/regionselection'
import './App.css'
import { ListItem } from '@material-ui/core'
import ListSheet from './components/list'
import ResultSheet from './components/result'

const URL = 'https://tcl-gocart-master-unwlqs5qfq-ew.a.run.app/api/list'
interface ListItem {
	id: string
	name: string
	image: string
	measurement: string
	pipUrl: string
	type: string
	subItems: ListItem[]
	quantity: number
}

function postData(data = '{}') {
	console.log(data)
	return fetch(URL, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: data,
	}).then(response => response.json())
}

async function saveList(region: string, list: Record<string, any>) {
	let payload = {
		retailUnit: region.substring(1, 3),
		items: Object.values(list),
	}
	let result = await postData(JSON.stringify(payload))
	return result.listId
}

const App: React.FC = () => {
	const [List, setList] = useState<Record<string, ListItem>>({})
	const [region, setRegion] = useState('')
	const [listID, setListID] = useState('')

	const selectRegion = (selected: Region | undefined) => {
		setRegion((selected || {}).path || '')
		setList({})
	}

	const addSelected = (p: Product | null) => {
		if (!p) return
		const current = List
		let modified = { ...current }
		const count = p.id in current ? current[p.id].quantity + 1 : 1
		modified[p.id] = {
			id: p.id,
			name: p.name,
			image: p.main_image_url,
			measurement: p.item_measure_reference_text,
			pipUrl: p.pip_url,
			type: p.type_name,
			subItems: [],
			quantity: count,
		}
		setList(modified)
	}

	let innerComponent = null

	if (listID === '') {
		innerComponent = (
			<React.Fragment>
				<div className={'search'}>
					<RegionSelect selectCountry={selectRegion}></RegionSelect>
					<SearchBar region={region} addSelected={addSelected}></SearchBar>
				</div>
				{Object.keys(List).length > 0 && (
					<ListSheet
						List={List}
						onSave={() => {
							saveList(region, List).then(id => setListID(id))
						}}></ListSheet>
				)}
			</React.Fragment>
		)
	} else {
		innerComponent = (
			<div className="centered">
				<ResultSheet code={listID}></ResultSheet>
			</div>
		)
	}

	return (
		<div className="App">
			<header className="App-header">
				<p>
					Search and add to your list <br /> When your done, share the list.
				</p>
			</header>
			<main className="App-main">{innerComponent}</main>
		</div>
	)
}

export default App
