import React from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			padding: theme.spacing(4, 5),
		},
	})
)

export default function ResultSheet(props: Record<string, string>) {
	const classes = useStyles()

	const { code } = props
	const url = 'https://list.ingka.dev?listId=' + code
	return (
		<Paper className={classes.root}>
			<Typography variant="h5" component="h5">
				List saved sucessfully
			</Typography>
			<Typography>
				Your list is created with id:{' '}
				<a href={url} target="_blank" rel="noopener noreferrer">
					{code}
				</a>
			</Typography>
			<br />
			<Button
				onClick={() => {
					document.location.reload()
				}}>
				Start over
			</Button>
		</Paper>
	)
}
