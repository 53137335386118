import 'isomorphic-fetch'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import throttle from 'lodash/throttle'

const API_host = 'https://sik.search.blue.cdtapps.com'
const API_path = '/search-box'
export interface Product {
	name: string
	type_name: string
	item_measure_reference_text: string
	main_image_url: string
	pip_url: string
	id: string
}

interface SearchBarProps {
	addSelected: (item: Product) => void
	region: string
}

export default function SearchBar(props: SearchBarProps) {
	const { addSelected, region } = props
	const noRegion = region === ''
	const [inputValue, setInputValue] = React.useState('')
	const [options, setOptions] = React.useState<Product[]>([])

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(event.target.value)
	}

	const search = React.useMemo(
		() =>
			throttle((input: string, API_region: string, callback: any) => {
				fetch(API_host + API_region + API_path + '?q=' + input + '&max-size=20')
					.then(resp => {
						return resp.json()
					})
					.then(json => {
						callback(json.searchBox.products || [])
					})
					.catch(err => {
						console.error(`failed with error: ${err}`)
					})
			}, 500),
		[]
	)

	React.useEffect(() => {
		let active = true

		if (inputValue === '' || region === '') {
			setOptions([])
			return undefined
		}

		search(inputValue, region, (results?: Product[]) => {
			if (active) {
				setOptions(results || [])
			}
		})

		return () => {
			active = false
		}
	}, [inputValue, search, region])

	return (
		<Autocomplete
			style={{ width: 300, margin: 10 }}
			disabled={noRegion}
			getOptionLabel={option => option.name || ''}
			filterOptions={x => x}
			options={options}
			autoComplete
			includeInputInList={true}
			freeSolo
			disableOpenOnFocus
			onChange={(_, value) => addSelected(value)}
			renderInput={params => (
				<TextField
					{...params}
					label="Search for products"
					variant="outlined"
					fullWidth
					onChange={handleChange}
				/>
			)}
			renderOption={option => {
				const matches = match(option.name, inputValue)
				const parts = parse(option.name, matches)

				return (
					<Grid container alignItems="center">
						<Grid item>
							<img
								className="thumbnail"
								src={option.main_image_url}
								alt={option.name}
							/>
						</Grid>
						<Grid item xs>
							{parts.map((part, index) => (
								<span
									key={index}
									style={{ fontWeight: part.highlight ? 700 : 400 }}>
									{part.text}
								</span>
							))}
							<Typography variant="body2" color="textSecondary">
								{option.item_measure_reference_text}
							</Typography>
						</Grid>
					</Grid>
				)
			}}
		/>
	)
}
