import React, { useState } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			padding: theme.spacing(4, 5),
			minWidth: 300,
		},
		progress: {
			margin: 0,
			marginTop: theme.spacing(3),
		},
	})
)

export default function ListSheetxw(props: Record<string, any>) {
	const [loading, setloading] = useState(false)
	const classes = useStyles()
	const { List, onSave } = props
	return (
		<Paper className={classes.root}>
			<div className="list">
				{Object.keys(List)
					.map(key => List[key])
					.sort((a, b) => (a.name < b.name ? -1 : 1))
					.map(item => {
						return (
							<Typography key={item.id}>
								<span className={'count'}>{item.quantity}. </span>
								{item.name + ' ' + item.measurement}
							</Typography>
						)
					})}
			</div>
			<Button
				variant="contained"
				color="default"
				disabled={loading}
				onClick={() => {
					setloading(true)
					onSave()
				}}
				startIcon={<CloudUploadIcon />}>
				Share
			</Button>
			{loading && (
				<LinearProgress className={classes.progress} variant="query" color="primary" />
			)}
		</Paper>
	)
}
