import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import regions from './regions.json'

function countryToFlag(isoCode: string) {
	return typeof String.fromCodePoint !== 'undefined'
		? isoCode
				.toUpperCase()
				.replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
		: isoCode
}

const useStyles = makeStyles({
	box: {
		width: 300,
		margin: 10,
	},
	option: {
		fontSize: 15,
		'& > span': {
			marginRight: 10,
			fontSize: 18,
		},
	},
})

export interface Region {
	code: string
	label: string
	path: string
}

interface RegionSelectProps {
	selectCountry: (selected: Region) => void
}

export default function RegionSelect(props: RegionSelectProps) {
	const classes = useStyles()
	const { selectCountry } = props
	return (
		<Autocomplete
			style={{ width: 300, margin: 10 }}
			id="country-select"
			options={countries}
			classes={{
				option: classes.option,
			}}
			autoHighlight
			onChange={(_, value) => selectCountry(value)}
			getOptionLabel={(option: Region) => option.label}
			renderOption={(option: Region) => (
				<React.Fragment>
					<span>{countryToFlag(option.code)}</span>
					{option.label}
				</React.Fragment>
			)}
			renderInput={params => (
				<TextField
					{...params}
					label="Choose a region"
					variant="outlined"
					fullWidth
					inputProps={{
						...params.inputProps,
						autoComplete: 'disabled',
					}}
				/>
			)}
		/>
	)
}

const countries = Object.entries(regions).map(([key, value]) => {
	return {
		code: key.substring(0, 2),
		label: key,
		path: value,
	}
})
